import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { connect } from 'react-redux'
class LightBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            photoIndex: 0,
            isOpen: false,
        }
    }
    render() {
        const { IsHungarian, lang_switch } = this.props
        const { photoIndex, isOpen } = this.state
        const Header = () => {
            switch (lang_switch) {
                case "HU":
                    return "Galéria"
                case "DE":
                    return "Galerie"
                case "GB":
                    return "Gallery"
                case "ES":
                    return "Es"
    
                default:
                    return "Galéria"
            }
        }
        return (
            <div>
               
                {isOpen && (
                    <Lightbox
                        mainSrc={this.props.images[photoIndex].image.childImageSharp.fluid.src}
                        nextSrc={
                            this.props.images[
                                (photoIndex + 1) % this.props.images.length
                            ]
                        }
                        prevSrc={
                            this.props.images[
                                (photoIndex + this.props.images.length - 1) %
                                    this.props.images.length
                            ]
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex +
                                        this.props.images.length -
                                        1) %
                                    this.props.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex + 1) % this.props.images.length,
                            })
                        }
                    />
                )}
                <button
                    type="button"
                    className="button is-large is-outlined"
                    onClick={() => this.setState({ isOpen: true })}
                >
                    <Header />
                </button>
            </div>
        )
    }
}
export default connect(
    state => ({
        IsHungarian: state.app.IsHungarian,
        lang_switch: state.app.lang_switch
    }),
    null
)(LightBox)
