import React from 'react'
import { render } from 'react-dom';
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Gallery from 'react-grid-gallery'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'


const PreviewCompatibleImage = ({ image }) => {



    const images = []

    if (!!image && typeof image.length != 'undefined') {
        image.map(img =>
            images.push({
                original: !!img.image.childImageSharp ? img.image.childImageSharp.fluid.src : img.image,
                originalAlt: !!img.image.childImageSharp ? img.image.childImageSharp.fluid.src : img.image

            })
        )
        return (

           
                <ImageGallery
                
                    items={images}
                    showThumbnails={false}
                    thumbnailPosition={'bottom'}
                    showBullets={false}
                    showIndex={false}
                    autoPlay={true}
                    slideInterval={3000}
                    slideDuration={1000}
                    showNav={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                />
                
           




        )
    }
    if (!!image && typeof image.length == 'undefined') {
        return <Img fluid={!!image.childImageSharp ? image.childImageSharp.fluid : image} alt="profil" />
    }
    return ""
}

PreviewCompatibleImage.propTypes = {
    imageInfo: PropTypes.shape({
        alt: PropTypes.string,
        childImageSharp: PropTypes.object,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
        style: PropTypes.object,
    }).isRequired,
}

export default PreviewCompatibleImage
